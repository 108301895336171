<template>
  <div>
    <v-container :class="{ 'pt-5': app !== 'campus' }">
      <v-skeleton-loader
        v-if="!escuelaTienePermiso"
        type="list-item-two-line@5"
      ></v-skeleton-loader>
      <v-card v-else rounded="lg">
        <v-toolbar dense color="secondary" dark flat class="text-h6">
          <v-btn icon @click="$router.push('/examenes')"
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >
          <v-spacer></v-spacer> {{ examen ? examen.nombre : "" }}
          <v-spacer></v-spacer>
          <v-menu v-if="permisoEscritura" offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(menuExamenItem, index) in menuExamen"
                :key="index"
                @click="menuAction(menuExamenItem)"
              >
                <v-list-item-title>
                  <v-icon small class="mr-2">{{ menuExamenItem.icon }}</v-icon>
                  <span>{{ menuExamenItem.text }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>

        <v-card-text v-if="loading">
          <loading-examen></loading-examen>
        </v-card-text>

        <v-card-text v-else-if="examen">
          <v-container>
            <div
              v-if="!examen.descripcion || examen.descripcion === '<p></p>'"
              class="mb-3"
            >
              <span>No se ha capturado descripción.</span>
            </div>
            <div
              v-else
              v-html="examen.descripcion"
              class="ck-content mb-3"
            ></div>
          </v-container>
          <examen-secciones
            :secciones="examen.secciones"
            :permisoEscritura="permisoEscritura"
            @nuevoLog="nuevoLog"
          ></examen-secciones>
        </v-card-text>
        <usuarios-examen
          :permisoEscritura="permisoEscritura"
          @nuevoLog="nuevoLog"
        ></usuarios-examen>
        <logs-examen v-if="examen" :logs="examen.logs"></logs-examen>
      </v-card>
    </v-container>

    <modificar-examen-dialog
      v-if="mostrarModificacionExamen"
      :mostrar="mostrarModificacionExamen"
      :examen="examen"
      @examenGuardado="examenGuardado"
      @nuevoLog="nuevoLog"
      @cancelar="mostrarModificacionExamen = false"
    ></modificar-examen-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getExamen } from "./examenes.service";

import loadingExamen from "./loadingExamen.vue";
import examenSecciones from "./examenSecciones.vue";
import usuariosExamen from "./usuariosExamen.vue";
import logsComponent from "../widgets/logs.vue";
import { permisoMenuEscuela } from '../../helpers/utilerias';

export default {
  components: {
    "loading-examen": loadingExamen,
    "modificar-examen-dialog": () => import("./crearExamenDialog.vue"),
    "examen-secciones": examenSecciones,
    "usuarios-examen": usuariosExamen,
    "logs-examen": logsComponent,
  },

  data: () => ({
    loading: true,
    examen: null,
    permisoEscritura: false,
    menuExamen: [
      {
        text: "Modificar nombre y descripción",
        action: "modificarExamen",
        icon: "mdi-square-edit-outline",
      },
    ],
    mostrarModificacionExamen: false,
    escuelaTienePermiso: false,
  }),

  computed: {
    ...mapGetters(["sessionToken", "app", "menu"]),
  },

  watch: {
    sessionToken(value) {
      if (value) {
        if (!permisoMenuEscuela("Exámenes")) this.$router.push("/dashboard");
        else {
          this.escuelaTienePermiso = true;
          this.getExamen();
        }
      }
    },
  },

  mounted() {
    if (this.sessionToken) {
      if (!permisoMenuEscuela("Exámenes")) this.$router.push("/dashboard");
      else {
        this.getExamen();
        this.escuelaTienePermiso = true;
      }
    }
  },

  methods: {
    menuAction(data) {
      switch (data.action) {
        case "modificarExamen":
          this.mostrarModificacionExamen = true;
          break;
      }
    },

    async getExamen() {
      const { idExamen } = this.$route.params;
      this.loading = true;

      try {
        const serverResponse = await getExamen(idExamen);
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          this.$router.push("/examenes");
        } else {
          this.examen = serverResponse.examen;
          this.permisoEscritura = serverResponse.permisoEscritura;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    examenGuardado({ nombre, descripcion }) {
      this.examen.nombre = nombre;
      this.examen.descripcion = descripcion;
      this.mostrarModificacionExamen = false;
    },

    nuevoLog(nuevoLog) {
      this.examen.logs.unshift(nuevoLog);
    },
  },
};
</script>
