import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,{class:{ 'pt-5': _vm.app !== 'campus' }},[(!_vm.escuelaTienePermiso)?_c(VSkeletonLoader,{attrs:{"type":"list-item-two-line@5"}}):_c(VCard,{attrs:{"rounded":"lg"}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push('/examenes')}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_vm._v(" "+_vm._s(_vm.examen ? _vm.examen.nombre : "")+" "),_c(VSpacer),(_vm.permisoEscritura)?_c(VMenu,{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c(VList,_vm._l((_vm.menuExamen),function(menuExamenItem,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.menuAction(menuExamenItem)}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(menuExamenItem.icon))]),_c('span',[_vm._v(_vm._s(menuExamenItem.text))])],1)],1)}),1)],1):_vm._e()],1),(_vm.loading)?_c(VCardText,[_c('loading-examen')],1):(_vm.examen)?_c(VCardText,[_c(VContainer,[(!_vm.examen.descripcion || _vm.examen.descripcion === '<p></p>')?_c('div',{staticClass:"mb-3"},[_c('span',[_vm._v("No se ha capturado descripción.")])]):_c('div',{staticClass:"ck-content mb-3",domProps:{"innerHTML":_vm._s(_vm.examen.descripcion)}})]),_c('examen-secciones',{attrs:{"secciones":_vm.examen.secciones,"permisoEscritura":_vm.permisoEscritura},on:{"nuevoLog":_vm.nuevoLog}})],1):_vm._e(),_c('usuarios-examen',{attrs:{"permisoEscritura":_vm.permisoEscritura},on:{"nuevoLog":_vm.nuevoLog}}),(_vm.examen)?_c('logs-examen',{attrs:{"logs":_vm.examen.logs}}):_vm._e()],1)],1),(_vm.mostrarModificacionExamen)?_c('modificar-examen-dialog',{attrs:{"mostrar":_vm.mostrarModificacionExamen,"examen":_vm.examen},on:{"examenGuardado":_vm.examenGuardado,"nuevoLog":_vm.nuevoLog,"cancelar":function($event){_vm.mostrarModificacionExamen = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }