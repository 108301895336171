<template>
  <v-container>
    <v-container
      class="d-flex justify-space-between align-center mx-y px-0 my-3"
    >
      <span class="subtitle-1">Secciones del examen</span>
      <v-btn
        v-if="permisoEscritura"
        color="primary"
        outlined
        small
        :disabled="loading"
        :loading="loading"
        @click="mostrarCrearSeccion = true"
      >
        <v-icon small class="mr-2">mdi-plus</v-icon>
        <span>Agregar sección</span>
      </v-btn>
    </v-container>
    <span
      v-if="seccionesLocal && !seccionesLocal.length"
      class="text--secondary"
      >No se han agregado secciones a este examen.</span
    >
    <v-list v-else>
      <draggable
        v-model="seccionesLocal"
        tag="div"
        ghost-class="ghost"
        handle=".handle"
        @change="cambiarOrden"
      >
        <template v-for="seccion in seccionesLocal">
          <v-list-item :key="seccion._id" class="item-seccion pl-0">
            <v-list-item-avatar
              v-if="permisoEscritura"
              class="mr-0"
              :class="{ handle: !loading }"
            >
              <v-btn icon style="cursor: move" :disabled="loading">
                <v-icon>mdi-dots-grid</v-icon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <router-link
                  :to="`${$route.fullPath}/seccion/${seccion._id}`"
                  class="link"
                  >{{ seccion.nombre }}
                </router-link>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-chip x-small>
                  {{
                    seccion && seccion.paginas ? seccion.paginas.length : "0"
                  }}
                  páginas
                </v-chip>
                <v-chip x-small class="mx-2">
                  {{ seccion && seccion.tiempo ? seccion.tiempo : "0" }}
                  minutos
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </draggable>
    </v-list>

    <crear-seccion-dialog
      v-if="mostrarCrearSeccion"
      :mostrar="mostrarCrearSeccion"
      @cancelar="mostrarCrearSeccion = false"
      @seccionGuardada="seccionGuardada"
      @nuevoLog="nuevoLog"
    ></crear-seccion-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { modificarSeccion } from "./examenes.service";

import draggable from "vuedraggable";

export default {
  props: {
    secciones: { type: Array, default: () => [] },
    permisoEscritura: { type: Boolean, default: false },
  },

  components: {
    draggable,
    "crear-seccion-dialog": () => import("./crearSeccionExamenDialog.vue"),
  },

  data: () => ({
    loading: false,
    seccionesLocal: [],
    mostrarCrearSeccion: false,
  }),

  watch: {
    secciones() {
      this.seccionesLocal = this.secciones;
    },
  },

  mounted() {
    this.seccionesLocal = this.secciones;
  },

  computed: {
    ...mapGetters(["colors"]),
  },

  methods: {
    async cambiarOrden(event) {
      this.loading = true;

      try {
        const { idExamen } = this.$route.params;
        const idSeccion = event.moved.element._id;
        const nuevoIndex = event.moved.newIndex;

        const requestData = { nuevoIndex };

        const serverResponse = await modificarSeccion({
          idSeccion,
          idExamen,
          data: requestData,
        });
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.nuevoLog(serverResponse.nuevoLog);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    seccionGuardada(seccion) {
      this.seccionesLocal = [...this.seccionesLocal, seccion];
      this.mostrarCrearSeccion = false;
    },

    nuevoLog(log) {
      this.$emit("nuevoLog", log);
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.ghost {
  opacity: 0.5;
  background: #e0e0e0;
}
</style>
