<template>
  <v-container>
    <v-row>
      <v-col md="6" sm="12" cols="12">
        <usuariosContenidoLoading
          v-if="loadingColaboradores"
          titulo="Colaboradores"
        />
        <UsuariosContenido
          v-else
          titulo="Colaboradores"
          tipo="colaboradores"
          :usuarios="colaboradores"
          :permisoEscritura="permisoEscritura"
          @agregarUsuario="abrirBuscador('colaboradores')"
          @quitarUsuario="quitarUsuarioAction"
        />
      </v-col>
      <v-col md="6" sm="12" cols="12">
        <usuariosContenidoLoading
          v-if="loadingCompartido"
          titulo="Compartido"
        />
        <UsuariosContenido
          v-else
          titulo="Compartido"
          tipo="compartido"
          :usuarios="compartido"
          :permisoEscritura="permisoEscritura"
          @agregarUsuario="abrirBuscador('compartido')"
          @quitarUsuario="quitarUsuarioAction"
        />
      </v-col>
    </v-row>

    <buscarUsuarioComponent
      v-if="agregarUsuario.mostrar"
      :loading="agregarUsuario.loading"
      :mostrar="agregarUsuario.mostrar"
      :excluir="noSearch"
      :ruta="agregarUsuario.ruta"
      :mostrarCorreo="true"
      @loadingStart="agregarUsuario.loading = true"
      @loadingStop="agregarUsuario.loading = false"
      @cerrar="agregarUsuario.mostrar = false"
      @usuarioAction="agregarUsuarioAction"
    />

    <quitarUsuarioContenido
      v-if="quitarUsuario.mostrar"
      :mostrar="quitarUsuario.mostrar"
      :usuario="quitarUsuario.usuario"
      :tipo="quitarUsuario.tipo"
      :ruta="quitarUsuario.ruta"
      @cancelar="quitarUsuario.mostrar = false"
      @usuarioEliminado="usuarioEliminado"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import UsuariosContenido from "../widgets/usuarios/usuariosContenido.vue";
import buscarUsuarioComponent from "../widgets/usuarios/buscarUsuario.vue";
import usuariosContenidoLoading from "../widgets/usuarios/usuariosContenidoLoading.vue";
import quitarUsuarioContenido from "../widgets/usuarios/quitarUsuarioContenido.vue";
import {
  obtenerUsuariosService,
  agregarUsuarioService,
} from "./examenes.service";

export default {
  props: {
    permisoEscritura: { type: Boolean, default: false },
  },

  components: {
    UsuariosContenido,
    buscarUsuarioComponent,
    usuariosContenidoLoading,
    quitarUsuarioContenido,
  },

  computed: {
    ...mapGetters(["sessionToken", "app"]),
    noSearch() {
      let listaCompartidos = this.compartido.map((x) => {
        return x._id;
      });
      let listaColaboradores = this.colaboradores.map((x) => {
        return x._id;
      });
      return listaCompartidos.concat(listaColaboradores);
    },
  },

  watch: {
    sessionToken() {
      this.cargarColaboradores();
      this.cargarCompartidos();
    },
  },

  async mounted() {
    this.idExamen = this.$route.params.idExamen;
    if (this.sessionToken) {
      this.cargarColaboradores();
      this.cargarCompartidos();
    }
  },

  data() {
    return {
      loadingColaboradores: true,
      loadingCompartido: true,
      ruta: "",
      compartido: [],
      colaboradores: [],
      agregarUsuario: {
        mostrar: false,
        loading: false,
        tipo: "",
        ruta: "",
      },
      quitarUsuario: {
        mostrar: false,
        usuario: {},
        tipo: "",
        ruta: "",
      },
    };
  },

  methods: {
    async cargarCompartidos() {
      try {
        this.loadingCompartido = true;
        const serverResponse = await obtenerUsuariosService(
          "compartido",
          this.idExamen
        );
        this.loadingCompartido = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.compartido = serverResponse.usuarios;
      } catch (error) {
        this.loadingCompartido = false;
        this.$appErrorMessage();
      }
    },
    async cargarColaboradores() {
      try {
        this.loadingColaboradores = true;
        const serverResponse = await obtenerUsuariosService(
          "colaboradores",
          this.idExamen
        );
        this.loadingColaboradores = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else this.colaboradores = serverResponse.usuarios;
      } catch (error) {
        this.loadingColaboradores = false;
        this.$appErrorMessage();
      }
    },
    abrirBuscador(tipo) {
      this.agregarUsuario.ruta = `buscar/usuario/${this.app}${
        this.app == "escuela" ? "/profesor" : ""
      }`;
      this.agregarUsuario.tipo = tipo;
      this.agregarUsuario.mostrar = true;
    },
    async agregarUsuarioAction(idUsuario) {
      try {
        const tipo = this.agregarUsuario.tipo;
        this.agregarUsuario.loading = true;
        const serverResponse = await agregarUsuarioService(
          tipo,
          this.idExamen,
          idUsuario
        );
        this.agregarUsuario.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          if (tipo == "colaboradores")
            this.colaboradores.push(serverResponse.usuario);
          if (tipo == "compartido")
            this.compartido.push(serverResponse.usuario);
          this.$emit("nuevoLog", serverResponse.nuevoLog);
        }
      } catch (error) {
        this.agregarUsuario.loading = false;
        this.$appErrorMessage();
      }
    },
    quitarUsuarioAction(data) {
      this.quitarUsuario.usuario = data.usuario;
      this.quitarUsuario.tipo = data.tipo;
      this.quitarUsuario.ruta = `examenes/usuarios/${data.tipo}/${this.$route.params.idExamen}`;
      this.quitarUsuario.mostrar = true;
    },
    usuarioEliminado(data) {
      if (data.tipo == "colaboradores") {
        const index = this.colaboradores.findIndex(
          (x) => x._id == data.idUsuario
        );
        this.colaboradores.splice(index, 1);
      }
      if (data.tipo == "compartido") {
        const index = this.compartido.findIndex((x) => x._id == data.idUsuario);
        this.compartido.splice(index, 1);
      }
      this.$emit("nuevoLog", data.serverResponse.nuevoLog);
    },
  },
};
</script>
